<template>
  <div class="mobile">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "index",
};
</script>

<style scoped lang="scss">
.mobile {
  width: 100%;
  height: 100%;
}
</style>
